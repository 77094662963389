import React from "react";
import loadable from "@loadable/component";
import useWindowDimensions from "@hooks/useWindowDimensions";
import useDelayedRender from "@hooks/useDelayedRender"
import backgroundImage from "@images/dedicated-teams/backgroundImage.png";
import backgroundImageMobile from "@images/dedicated-teams/backgroundImageMobile.png";
import FAQSection from "../Common/FAQSection/FAQSection";
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links";
import { SECOND_CATCH_UP_SECTION_TITLE, SECOND_CATCH_UP_SECTION_DESCRIPTION } from "./constants";
import { StyledContactUsButton } from "./styled";

const VideoSection = loadable(() =>
    import("@common/CommonSections/VideoSection/VideoSection")
);
const CaseStudiesSection = loadable(() =>
    import("@common/CommonSections/CaseStudiesSection/CaseStudiesSection")
);
const TechnologySection = loadable(() =>
    import("./components/TechnologySection/TechnologySection")
);
const WhyChooseSection = loadable(() =>
    import("./components/WhyChooseSection/WhyChooseSection")
);
const TeamTypesSection = loadable(() =>
    import("./components/TeamTypesSection/TeamTypesSection")
);
const StepsSectionWrapper = loadable(() =>
    import("./components/StepsSectionWrapper/StepsSectionWrapper")
);
const CrawlableSecondCatchUpSection = loadable(() =>
    import("@common/CommonSections/CrawlableSecondCatchUpSection")
);

const CASE_STUDIES_INDEXES = [
    { index: 33, order: 1 },
    { index: 3, order: 2 },
];

export const dedicatedFAQList = [
    {
        title: "What is a dedicated development team?",
        iconPath: [null],
        description: (
            <>
                A dedicated development team is a group of software developers,
                designers, and other professionals who are hired by a company or
                organization to work exclusively on a particular project or set
                of projects. Unlike a traditional project team that may have
                multiple projects to work on, a dedicated development team works
                solely on the project they are assigned to.
                <br />
                The dedicated team is fully committed to the project and is
                focused on meeting the project&apos;s specific needs and goals.
                The benefits of a dedicated development team include increased
                efficiency, faster development times, greater flexibility, and
                higher quality work.
            </>
        ),
    },
    {
        title: "What is a dedicated project team structure?",
        iconPath: [null],
        description: (
            <>
                A dedicated project team structure is a type of project
                management approach in which a team of professionals is assigned
                to work exclusively on a particular project or set of projects.
                <br />
                The structure of a dedicated project team typically includes the
                following roles:
                <br />
                - Project Manager who is responsible for overseeing the entire
                project and ensuring that it is completed on time, within
                budget, and to the required quality standards.
                <br />
                - Team Lead responsible for managing the day-to-day activities
                of the dedicated team and ensuring that the project remains on
                track.
                <br />
                - Developers and Designers that are responsible for developing
                the software or designing the product, as well as testing and
                debugging.
                <br />
                - Quality Assurance team is responsible for ensuring that the
                product meets the required quality standards and that it is free
                of defects.
                <br />
                - The Support and Maintenance team is responsible for providing
                ongoing support and maintenance for the project once it has been
                completed.
                <br />
                The dedicated project team structure is designed to provide a
                focused and efficient approach to project management.
            </>
        ),
    },
    {
        title: "How to hire a dedicated development team?",
        iconPath: [null],
        description: (
            <>
                Hiring a dedicated development team can be complex and each
                particular case might be different. However, by following these
                steps, you can find a dedicated development team that meets your
                project requirements and can deliver high-quality work on time
                and within budget:
                <br />
                1. Define your project requirements. This includes the scope of
                the project, the technologies needed, the timeline, and the
                budget.
                <br />
                2. Determine the type of dedicated team you need: an in-house
                team, offshore team, nearshore team, hybrid team, or managed
                team.
                <br />
                3. Search for potential candidates using online job boards,
                social media, and networking sites. You can also work with
                recruitment agencies or outsourcing companies.
                <br />
                4. Conduct interviews to assess candidates’ skills, experience,
                and fit with your project requirements and company culture. It
                is also smart to check the references of the candidates to
                ensure that they have a proven track record of delivering
                quality work.
                <br />
                5. Once you have selected a dedicated development team,
                negotiate the terms of the contract, including the scope of
                work, timelines, and payment terms.
                <br />
                6. Onboard the team and provide them with the necessary
                resources, including access to software tools, project
                management tools, and communication channels.
            </>
        ),
    },
    {
        title: "When is the dedicated team model a good choice?",
        iconPath: [null],
        description: (
            <>
                To outsource dedicated software development teams is a good
                choice if:
                <br />
                - You have a long-term project. By having a team that is
                dedicated to the project, you can ensure continuity and
                consistency in the project&apos;s development.
                <br />
                - You have a complex project. Complex projects that require
                multiple skills and expertise are best handled by a dedicated
                team.
                <br />
                - You have a specific budget. You can negotiate a fixed price
                contract with the team and avoid unexpected expenses.
                <br />
                - You need greater flexibility. You can scale up or down the
                team size depending on your project&apos;s requirements and
                adjust the project timeline accordingly.
                <br />
                - You need more control over the project. You can communicate
                directly with the team, provide feedback, and make changes to
                the project as needed.
                <br />
                Overall, the dedicated team model is a good choice for projects
                that require long-term commitment, flexibility, control, and
                specific expertise.
            </>
        ),
    },
    {
        title: "What are the best practices for managing outsourcing dedicated teams?",
        iconPath: [null],
        description: (
            <>
                The best practices for managing outsourcing dedicated teams,
                which ensure that your project is completed on time, within
                budget, and to the required quality standards, include:
                <br />
                - Define the project goals and expectations, including the scope
                of work, timelines, budget, and quality standards. Communicate
                these clearly to the dedicated team to ensure everyone is on the
                same page.
                <br />
                - Set up regular communication channels with the dedicated team,
                such as daily or weekly meetings using video conferencing,
                messaging apps, or project management tools.
                <br />
                - If you outsource dedicated software development teams ensure
                that the dedicated team has access to the necessary resources,
                such as software tools, hardware, and communication channels.
                <br />
                - Building trust and rapport with the dedicated team is crucial
                for effective project management. Establish a positive working
                relationship with the team and show appreciation for their work.
                <br />
                - Regularly track the team&apos;s progress and measure the
                results against the project goals and expectations. This will
                help you to identify any issues or areas for improvement and
                make necessary adjustments.
                <br />
                - Offer regular constructive feedback on the team’s work and
                provide them with the necessary support to help them succeed.
                <br />- If you hire dedicated development team in a different
                country, be aware of cultural differences that may impact
                communication and project management. Take steps to understand
                and respect these differences and adapt your management style
                accordingly.
            </>
        ),
    },
    {
        title: "Do you provide development teams for startups and enterprises?",
        iconPath: [null],
        description: (
            <>
                Yes, we do! Impressit specializes in building custom teams
                tailored to the specific needs of each client, whether they are
                a startup or an enterprise.
                <br />
                The Impressit team has excellent experience, expertise, and
                reputation, as well as the ability to provide the necessary
                resources and support to meet your project requirements. Hire
                dedicated development team with Impressit for the best results.
            </>
        ),
    },
    {
        title: "How do I track the progress of my remote software group?",
        iconPath: [null],
        description: (
            <>
                By following these tips, you can effectively track the progress
                of your outsourced development team and ensure that your project
                is completed on time, within budget, and to the required quality
                standards:
                <br />
                1.Define clear project goals and metrics at the start of the
                project. This will help you to track progress and measure the
                results.
                <br />
                2.Use project management tools, such as Jira, Trello, or Asana,
                to assign tasks, track progress, and monitor team performance.
                <br />
                3.Schedule regular check-ins with the remote software group to
                discuss progress and any issues or concerns.
                <br />
                4.Establish a reporting system with regular progress reports,
                project updates, and metrics to track progress and measure
                results.
                <br />
                5.Encourage open communication with the remote software group.
                Create a culture of transparency and encourage team members to
                ask questions and provide feedback.
            </>
        ),
    },
    {
        title: "Why should you choose a dedicated development team?",
        iconPath: [null],
        description: (
            <>
                By choosing a dedicated development team, you can leverage their
                expertise, flexibility, cost-effectiveness, and faster
                time-to-market, while reducing risk and maintaining control over
                your project.
                <br />
                Dedicated software development teams are typically composed of
                experienced professionals with expertise in a specific
                technology or industry. This means that they have the necessary
                skills and knowledge to deliver high-quality work. Dedicated
                development teams are also flexible so you can scale up or down
                the team size as per the project requirements.
                <br />
                With a dedicated development team, you have better control and
                management over your project. You can set clear project goals,
                timelines, and quality standards, and monitor progress and
                performance closely.
                <br />
                Therefore, hiring a dedicated development team can be
                cost-effective as you do not have to invest in setting up an
                in-house team, training, or providing benefits. It also reduces
                the risk of delays or project failure as the team is solely
                focused on your project and has the necessary resources and
                expertise to ensure its success.
            </>
        ),
    },
];

const DedicatedTeams = ({ caseStudies }) => {
    const { isMobile } = useWindowDimensions();
    const { isDelayed } = useDelayedRender(100);

    return (
      <div className="product-development">
      <VideoSection
                backgroundImage={
                    isMobile ? backgroundImageMobile : backgroundImage
                }
                title="Dedicated teams for software development"
                description={`
                        Hire our skilled in-house experts who match your technology stack and love their work. 
                        Find the remote team that will be fully dedicated to your project and complete your 
                        in-house team perfectly. If you look for proactive creative thinkers — hire outsourced development team with Impressit!
                    `}
            />
    <TechnologySection />
      {isDelayed && (
        <>
      <StepsSectionWrapper />
          <CaseStudiesSection
            caseStudies={caseStudies}
            indexes={CASE_STUDIES_INDEXES}
            heading="Case studies"
          />
          <WhyChooseSection />
          <TeamTypesSection />
          <FAQSection title="FAQ" FAQList={dedicatedFAQList} />
          <CrawlableSecondCatchUpSection
            title={SECOND_CATCH_UP_SECTION_TITLE}
            description={SECOND_CATCH_UP_SECTION_DESCRIPTION}
          >
            <StyledContactUsButton
              href={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}
            >
              Contact us
            </StyledContactUsButton>
          </CrawlableSecondCatchUpSection>
        </>
      )}
    </div>
    );
};

export default DedicatedTeams;
